import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as _91item_93GHVunr73gyMeta } from "/vercel/path0/pages/events/[item].vue?macro=true";
import { default as indexuedj0fLb5OMeta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as _91item_93AtcCLGC5rsMeta } from "/vercel/path0/pages/highlights/[item].vue?macro=true";
import { default as indexU9GiN3ozqWMeta } from "/vercel/path0/pages/highlights/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as _91item_93zKgFSelgFtMeta } from "/vercel/path0/pages/vacancies/[item].vue?macro=true";
import { default as indexp3qWQtgtPJMeta } from "/vercel/path0/pages/vacancies/index.vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "events-item___en",
    path: "/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue").then(m => m.default || m)
  },
  {
    name: "events-item___nl",
    path: "/nl/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue").then(m => m.default || m)
  },
  {
    name: "events___en",
    path: "/events",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___nl",
    path: "/nl/events",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "gallery___en",
    path: "/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___nl",
    path: "/nl/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "highlights-item___en",
    path: "/highlights/\\:item",
    component: () => import("/vercel/path0/pages/highlights/[item].vue").then(m => m.default || m)
  },
  {
    name: "highlights-item___nl",
    path: "/nl/bezienswaardigheden/:item()",
    component: () => import("/vercel/path0/pages/highlights/[item].vue").then(m => m.default || m)
  },
  {
    name: "highlights___en",
    path: "/highlights",
    component: () => import("/vercel/path0/pages/highlights/index.vue").then(m => m.default || m)
  },
  {
    name: "highlights___nl",
    path: "/nl/bezienswaardigheden",
    component: () => import("/vercel/path0/pages/highlights/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___nl",
    path: "/nl/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "vacancies-item___en",
    path: "/vacancies/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue").then(m => m.default || m)
  },
  {
    name: "vacancies-item___nl",
    path: "/nl/vacatures/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue").then(m => m.default || m)
  },
  {
    name: "vacancies___en",
    path: "/vacancies",
    component: () => import("/vercel/path0/pages/vacancies/index.vue").then(m => m.default || m)
  },
  {
    name: "vacancies___nl",
    path: "/nl/vacatures",
    component: () => import("/vercel/path0/pages/vacancies/index.vue").then(m => m.default || m)
  }
]